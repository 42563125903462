import Cookies from "js-cookie";
import { isWidgetMode, onCookieChange } from "./frameService";

/**
 * Set cookie for context
 * @param object Saved object {compare: {carsIdList: [1, 2]}}
 */
export const setContextCookie = object => {
  let context = Cookies.getJSON("context") || {};
  for (let contextName in object) {
    for (let stateName in object[contextName]) {
      if (!context[contextName]) {
        context[contextName] = {};
      }
      context[contextName][stateName] = object[contextName][stateName];
    }
  }
  if (isWidgetMode) {
    onCookieChange("ilsa_data", context);
  } else {
    Cookies.set("ilsa_data", context);
  }
};

/**
 * Get cookie from context
 * @param defaultContext Context from SSR
 */
export const getContextFromCookie = defaultContext => {
  let cookieContext = Cookies.getJSON("ilsa_data") || {};
  if (defaultContext) {
    for (let contextName in cookieContext) {
      if (!defaultContext[contextName]) {
        defaultContext[contextName] = cookieContext[contextName];
      } else {
        for (let stateName in cookieContext[contextName]) {
          if (defaultContext[contextName][stateName] === undefined) {
            defaultContext[contextName][stateName] =
              cookieContext[contextName][stateName];
          }
        }
      }
    }
    return defaultContext;
  }
  return cookieContext;
};
