import { matchPath } from "react-router";

export const getQueryStringValueFromLocation = (key, locationSearch) => {
  locationSearch = locationSearch ? locationSearch : window.location.search;
  return decodeURIComponent(
    locationSearch.replace(
      new RegExp(
        "^(?:.*[&\\?]" +
          encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
          "(?:\\=([^&]*))?)?.*$",
        "i",
      ),
      "$1",
    ),
  );
};

/**
 * Format number function
 * 123412323,78 -> 123 412 323,78
 * @param num Input value
 * @return string value
 */
export const formatNumber = num => {
  if (num === 0) {
    return "0";
  }
  return !!num
    ? num
        .toString()
        .replace(/\s/g, "")
        .replace(/(\d)(?=(\d{3})+($|[\.\,]\d+))/g, "$1 ")
    : "";
};

/**
 * Inverse function
 * 123 412 323,78 -> 123412323.78
 * @param value
 * @return Reformatted value
 */
export const reformatNumber = value =>
  typeof value !== "undefined"
    ? value.replace(/\s/g, "").replace(/\,/g, ".")
    : value;

export const declOfNum = (num, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
  ];
};

export const getQueryParams = query => {
  query = query.split("+").join(" ");

  let params = {};
  let tokens;
  let re = /[?&]?([^=]+)=([^&]*)/g;

  while ((tokens = re.exec(query))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

export const getQueryStringFromObj = obj => {
  return Object.keys(obj)
    .reduce((a, k) => {
      a.push(k + "=" + encodeURIComponent(obj[k]));
      return a;
    }, [])
    .join("&");
};

export const getUpdatedUrlSearch = (name, values, searchPath) => {
  const search = searchPath ? searchPath : window.location.search;
  let searchParams = search.substr(1).split("&");
  if (
    !searchParams.find(item => {
      return item.indexOf(name) !== -1;
    })
  ) {
    searchParams.push(`${name}=${values.join(",")}`);
  }

  searchParams.sort();
  searchParams = searchParams.map(param => {
    if (param.indexOf(name) !== -1) {
      if (name === "expressions") {
        return values.length > 0
          ? `${name}=${values.join(";").replace(/=/g, ":")}`
          : "";
      } else {
        return values.length > 0 ? `${name}=${values.join(",")}` : "";
      }
    }
    return param;
  });
  searchParams = searchParams.filter(item => {
    return item !== "";
  });
  return "?" + searchParams.join("&");
};

export const removeURLParameter = (sourceURL, key) => {
  const list = sourceURL.split("?");
  const hasSearch = sourceURL.indexOf("?") !== -1;
  const hasPathName =
    (hasSearch && list.length !== 1) || (!hasSearch && list.length === 1);
  let rtn = hasPathName ? list[0] : "";
  let param;
  let paramsArr = [];
  const queryString =
    hasSearch && hasPathName ? list[1] : !hasPathName ? list[0] : "";
  if (queryString !== "") {
    paramsArr = queryString.split("&");
    for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
      param = paramsArr[i].split("=")[0];
      if (param === key) {
        paramsArr.splice(i, 1);
      }
    }
    rtn = rtn + (paramsArr.length > 0 ? "?" + paramsArr.join("&") : "");
  }
  return rtn;
};

/**
 * Format phone number
 * @param phone Input phone
 * @return Formatted phone
 */
export const formatPhone = phone => {
  if (phone.match(/([ +()-])/g) && phone.match(/([ +()-])/g).length > 0) {
    return phone;
  }
  return phone.replace(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/, "$1 $2 $3 $4 $5");
};

/**
 * Get filter params
 * @param filters Filters list
 * @return Selected filters
 */
export const getSelectedFilterParams = filters => {
  const params = {
    brand: "",
    brandPath: null,
    model: "",
    version: "",
  };

  filters.forEach(filter => {
    if (!filter.selected) {
      return;
    }

    switch (filter.name) {
      case "Марка":
        const brand = filter.values.find(val => val.checked === true);
        params.brand = brand.name;
        params.brandPath = brand.slug;
        break;

      case "Модель":
        params.model = filter.values.find(val => val.checked === true).name;
        break;

      case "Комплектация":
        params.version = filter.values
          .filter(val => val.checked === true)
          .map(version => version.name);
        break;

      default:
        if (filter.__typename === "EnumFilter") {
          if (typeof params.additional === "undefined") {
            params.additional = [];
          }

          params.additional.push(
            filter.values
              .filter(val => val.checked === true)
              .map(val => val.name)
              .join(", "),
          );
        }
    }
  });

  return params;
};

/**
 * Obtain selected region name from the state
 * @param regions List of regions
 * @param selected Map of selected items
 * @return (First) selected region's name
 */
export const getSelectedRegionName = (regions, selected) => {
  const selectedRegionsList = regions.filter(region =>
    selected.some(item => item === region.id),
  );

  return selectedRegionsList.length === 1 ? selectedRegionsList[0].namePre : "";
};

/**
 * Generate region's url
 * @param region Region's path
 * @returns Region's url
 */
export const getRegionUrl = region =>
  `${region ? (region[0] === "/" ? region : `/${region}`) : ""}/cars/new`;

/**
 * Generate brand's url
 * @param region Region's path
 * @param brand Brand's path
 * @return string url including region
 */
export const getBrandUrl = (region, brand) =>
  `${getRegionUrl(region)}${brand ? `/${brand}` : ""}`;

/**
 * Generate model's url
 * @param model Model's path
 * @param region Region's path
 * @param brand Brand's path (Is optional, due to model could containt it)
 * @param search Additional query params
 * @returns string url (including brand and region)
 */
export const getModelUrl = (model, region, brand, search) =>
  `${getBrandUrl(region, brand)}${
    model ? `/${model.replace(/^\/?/g, "")}` : ""
  }${search ? `?${search.replace(/[?]/g, "")}` : ""}`;

/**
 * Generate offer's url
 * @param offerId Offer's ID
 * @param model Model's path (included brand's path)
 * @param region Region's path
 * @param search Additional query params
 * @returns string url (including model, brand and region)
 */
export const getOfferPageUrl = (offerId, model, region, search) =>
  `${getModelUrl(model, region)}${offerId ? `/${offerId}` : ""}${
    search ? `?${search.replace(/[?]/g, "")}` : ""
  }`;

/**
 * Generate offer dealers page url
 * @param offerId Offer's ID
 * @param model Model's path (included brand's path)
 * @param region Region's path
 * @param search Additional search params
 * @returns string dealers page url
 */
export const getDealersPageUrl = (offerId, model, region, search) =>
  `${getOfferPageUrl(offerId, model, region)}/offers${
    search ? `?${search.replace(/[?]/g, "")}` : ""
  }`;

/**
 * Generate offer dealers on map page url
 * @param offerId Offer's ID
 * @param model Model's path (included brand's path)
 * @param region Region's path
 * @param search Additional search params
 * @returns string map page url
 */
export const getMapPageUrl = (offerId, model, region, search) =>
  `${getOfferPageUrl(offerId, model, region)}/map${
    search ? `?${search.replace(/[?]/g, "")}` : ""
  }`;

/**
 * Generate compare's url
 * @param region Region's path
 * @param carsIdList Cars id list for compare
 * @returns string url
 */
export const getCompareUrl = (region, carsIdList) => {
  const newIdList = carsIdList.map(id => id.replace(/==$/g, ""));
  return `${region || ""}/compare?id=${newIdList.join(",")}`;
};

/**
 * Convert filter value to expression
 * @param filter Filter's data
 * @returns Expression string
 */
export const filterToExpressionValue = filter =>
  filter.__typename === "EnumFilter"
    ? filter.values
        .filter(value => value.checked)
        .map(value => value.id)
        .join(",")
    : `${
        typeof filter.from !== "undefined"
          ? filter.from.endsWith(".")
            ? filter.from.slice(0, -1)
            : filter.from
          : ""
      }..${
        typeof filter.to !== "undefined"
          ? filter.to.endsWith(".")
            ? filter.to.slice(0, -1)
            : filter.to
          : ""
      }`;

/**
 * Parse expression to filter value
 * @param expression
 * @return object params
 */
export const expressionToFilter = expression => {
  const [filterId, value] = expression.split("=");
  const numberFilterMarker = value.indexOf("..");
  let filterValue = {};

  if (numberFilterMarker >= 0) {
    filterValue = {
      value: {
        from: value.substring(0, numberFilterMarker),
        to: value.substring(numberFilterMarker + 2),
      },
    };
  } else {
    filterValue = {
      value: value.split(","),
    };
  }

  return {
    filterId,
    ...filterValue,
  };
};

/**
 * Generate url based on current fitlers
 * @param regions List of regions
 * @param selectedRegions Map of selected regions
 * @param radius Radius
 * @param filters Filters list
 * @param sorting Current sorting options
 * @param skip Skip cars in request
 * @param search search from URL
 * @returns string
 */
export const generateUrlFromFilters = (
  regions,
  selectedRegions,
  radius,
  filters,
  sorting,
  skip,
  search,
) => {
  const numberOfSelectedRegions = selectedRegions.length;
  const selectedRegionName =
    numberOfSelectedRegions === 1
      ? regions.find(region => selectedRegions[0] === region.id).resourcePath
      : "";

  const selectedFilters = filters.reduce(
    (res, filter) => {
      if (filter.selected) {
        switch (filter.id) {
          case "1101":
            res.brand = filter.values.find(value => value.checked).slug;
            break;

          case "1102":
            res.model = filter.values.find(value => value.checked).slug;
            break;

          default:
            res.others.push({
              id: filter.id,
              value: filterToExpressionValue(filter).replace("..", "~"),
            });
        }
      }

      return res;
    },
    { brand: "", model: "", others: [] },
  );

  const [sortBy = "PRICE", sortDir = "ASC"] = (sorting || "").split("_");

  const queryParams = generateQueryString(
    {
      filters: selectedFilters.others,
      ...{ sortBy, sortDir },
      ...(radius !== undefined && numberOfSelectedRegions === 1
        ? { radius }
        : {}),
      ...(skip ? { skip } : {}),
    },
    search,
  );

  return (
    getModelUrl(
      selectedFilters.model,
      selectedRegionName,
      selectedFilters.brand,
    ) + (queryParams ? `?${queryParams}` : "")
  );
};

/**
 * Parse url to filters
 * @param pathname Url's path
 * @param search Url's search
 * @return object
 */
export const parseUrlToFilter = (pathname, search) => {
  const region = pathname
    ? pathname.replace(/(\/cars[\s\S]+)|(\/compare)|([\/])/g, "")
    : null;
  const parsedPath = pathname
    ? pathname.replace(/((.*?)\/cars\/new\/)|((.*?)\/compare)/g, "").split("/")
    : [];
  const parsedSearch = getQueryParams(search || "");

  return {
    modelPath: parsedPath[0]
      ? parsedPath[0] + (parsedPath[1] ? "/" + parsedPath[1] : "")
      : null,
    region: region || null,
    brand: parsedPath[0] || null,
    model: parsedPath[1] || null,
    version: parsedPath[2] || null,
    expressions: parsedSearch.expressions
      ? parsedSearch.expressions
          .split(";")
          .map(expression => expression.replace(":", "=").replace("~", ".."))
      : null,
    sortBy: parsedSearch.sortBy || "PRICE",
    sortDir: parsedSearch.sortDir || "ASC",
    radius: parsedSearch.radius || null,
    skip: parsedSearch.skip || null,
  };
};

/**
 * Build query string from object params
 * @param params Object with query params
 * @param search search from URL
 * @returns Query string
 */
export const generateQueryString = (params, search) => {
  const queryObject = {};

  if (search) {
    //Для сохранения всех сторонних параметров в ссылке (utm и тд.)
    const params = new URLSearchParams(search);
    params.forEach((val, key) => {
      if (key !== "expressions") {
        queryObject[key] = val;
      }
    });
  }

  if (params.sortBy) {
    queryObject.sortBy = params.sortBy;
  }

  if (params.sortDir) {
    queryObject.sortDir = params.sortDir;
  }

  if (params.filters && params.filters.length > 0) {
    queryObject.expressions = params.filters
      .map(filter => `${filter.id}:${filter.value.replace("..", "~")}`)
      .join(";");
  }

  if (params.radius !== undefined) {
    queryObject.radius = params.radius;
  }

  if (params.skip) {
    queryObject.skip = params.skip;
  } else {
    delete queryObject.skip;
  }

  if (params.selectedDealers) {
    queryObject.selectedDealers = params.selectedDealers;
  }

  return getQueryStringFromObj(queryObject);
};

export const createPathBasedOnExpressions = similarExpressions => {
  similarExpressions = similarExpressions.filter(expression => {
    let filterId = expression.split("=")[0];
    return filterId !== "1101" && filterId !== "1102";
  });
  let expressions = similarExpressions.join(";");
  let newDefaultFilters = Object.assign(
    { after: "0", expressions: [], sortBy: "PRICE", sortDir: "ASC" },
    { expressions },
  );
  let newParams = [];

  for (let key of Object.keys(newDefaultFilters)) {
    newParams.push(
      `${key}=${encodeURIComponent(
        newDefaultFilters[key].replace("=", ":").replace("..", "~"),
      )}`,
    );
  }
  return newParams.join("&");
};

/**
 * Obtain getContext request params from location
 * @param location location
 * @param regions List of regions
 * @param selectedRegions Selected regions
 * @param radius Selected radius
 * @return getContext request params
 */
export const getFilterRequestParamsFromLocation = (
  location,
  regions,
  selectedRegions,
  radius = undefined,
) => {
  const params = {};

  const selectedFilters = parseUrlToFilter(location.pathname, location.search);
  const selectedRegionsIds = selectedRegions || [];

  let selectedRegionIdFromUrl = null;
  if (selectedFilters.region) {
    const selectedRegion = regions.find(
      region => region.resourcePath === "/" + selectedFilters.region,
    );
    if (selectedRegion) {
      selectedRegionIdFromUrl = selectedRegion.id;
    }
  }

  if (
    selectedFilters.region &&
    selectedRegionIdFromUrl !== selectedRegionsIds[0]
  ) {
    if (selectedRegionIdFromUrl) {
      params.geo = {
        ids: [selectedRegionIdFromUrl],
        ...(selectedFilters.radius !== undefined
          ? { radius: Number(selectedFilters.radius) }
          : {}),
      };
    } else {
      params.geo = {
        ids: [],
        ...(selectedFilters.radius !== undefined
          ? { radius: Number(selectedFilters.radius) }
          : {}),
      };
      params.geoPath = selectedFilters.region;
    }
  } else if (selectedRegionsIds.length > 0) {
    params.geo = {
      ids: selectedRegionsIds,
      ...(radius !== undefined ? { radius: Number(radius) } : {}),
    };
  }

  if (selectedFilters.modelPath !== null) {
    params.resourcePath = selectedFilters.modelPath;
  }

  if (selectedFilters.expressions) {
    params.expressions = selectedFilters.expressions;
  }

  if (selectedFilters.sortBy) {
    params.sortBy = selectedFilters.sortBy;
  }

  if (selectedFilters.sortDir) {
    params.sortDir = selectedFilters.sortDir;
  }

  if (selectedFilters.skip) {
    params.after = selectedFilters.skip;
  }

  return params;
};

/**
 * Added region path to current url
 * @param location location
 * @param regions regions list
 * @param selectedRegions Selected regions
 * @param radius Selected radius
 * @param resetOldData Delete old region's data from url
 * @return string with region and radius
 */
export const generateUrlWithRegionAndRadius = (
  location,
  regions,
  selectedRegions,
  radius,
  resetOldData = false,
) => {
  let pathname = location.pathname;
  let search = location.search;

  if (pathname.indexOf("page") !== -1) {
    return;
  }

  const selectedFilters = parseUrlToFilter(location.pathname, location.search);

  const selectedRegionsLength = selectedRegions.length;
  if (selectedRegionsLength === 1 && regions.length > 0) {
    const selectedRegion = regions.find(
      region => selectedRegions[0] === region.id,
    );

    if (location.pathname !== "/") {
      if (!selectedFilters.region || resetOldData) {
        pathname = `${selectedRegion.resourcePath}/${
          location.pathname.length <= 1
            ? "cars/new"
            : location.pathname
                .replace(/((.*?)\/cars\/new)/g, "cars/new")
                .replace(/((.*?)\/compare)/g, "compare")
        }`;
      }
      if ((!selectedFilters.radius && radius) || (resetOldData && radius)) {
        search = getUpdatedUrlSearch("radius", [radius], location.search);
      }
    }
  } else if (selectedRegionsLength === 0 || selectedRegionsLength > 1) {
    pathname = `${location.pathname
      .replace(/((.*?)\/cars\/new)/g, "/cars/new")
      .replace(/((.*?)\/compare)/g, "/compare")}`;
    search = removeURLParameter(search, "radius");
  }

  return pathname + search;
};

/**
 * Get value from expressions
 * @param expressionId expression Id
 * @param query search query
 * @return Value of expression
 */
export const getExpressionValue = (expressionId, query) => {
  const expressions = getQueryParams(query).expressions;
  if (expressions) {
    const expressionIdIndex = expressions.indexOf(expressionId);
    if (expressionIdIndex !== -1) {
      const endIndex = expressions.indexOf(";", expressionIdIndex);
      let selectedExpression = null;
      if (endIndex !== -1) {
        selectedExpression = expressions.slice(
          expressionIdIndex + expressionId.length + 1,
          endIndex,
        );
      } else {
        selectedExpression = expressions.slice(
          expressionIdIndex + expressionId.length + 1,
        );
      }
      const value = selectedExpression.split(",");
      if (value) {
        return value;
      }
    }
  }
  return [];
};

/**
 * Get expression with updated value in expressionId
 * @param expressionId expression Id
 * @param newValue list of new values
 * @param query search query
 * @return String of expression value
 */
export const getUpdatedExpressionValuesString = (
  expressionId,
  newValue,
  query,
) => {
  const expressionsString = getQueryParams(query).expressions || "";
  if (newValue.length === 0 && expressionsString === "") {
    return null;
  }

  let expressions =
    expressionsString === ""
      ? []
      : expressionsString.split(";").map(expression => {
          const items = expression.split(":");
          return {
            id: items[0],
            value: items[1],
          };
        });

  if (newValue.length === 0 && expressions.length === 0) {
    return null;
  }

  if (newValue.length > 0) {
    let isExpressionIdExist = false;
    expressions.forEach(expression => {
      if (expression.id === expressionId) {
        expression.value = newValue.join(",");
        isExpressionIdExist = true;
      }
    });
    if (!isExpressionIdExist) {
      expressions.push({
        id: expressionId,
        value: newValue,
      });
    }
  } else {
    expressions = expressions.filter(
      expression => expression.id !== expressionId,
    );
  }

  let result = "";
  expressions.forEach((expression, index) => {
    result += `${expression.id}:${expression.value}`;
    if (index < expressions.length - 1) {
      result += ";";
    }
  });
  return encodeURIComponent(result);
};

/**
 * Generate correct url with region and radius and without excess parameters
 * @param location location
 * @param regions regions list
 * @param selectedRegions Selected regions
 * @param radius Selected radius
 * @param currentPage identification of current page
 * @return string with region and radius
 */
export const generateCorrectUrl = (
  location,
  regions,
  selectedRegions,
  radius,
  currentPage,
) => {
  let urlWithRegionAndRadius = generateUrlWithRegionAndRadius(
    location,
    regions,
    selectedRegions,
    radius,
  );
  if (currentPage === "offer-detail") {
    urlWithRegionAndRadius = removeURLParameter(
      urlWithRegionAndRadius,
      "selectedDealers",
    );
  } else {
    urlWithRegionAndRadius = removeURLParameter(
      urlWithRegionAndRadius,
      "selectedTab",
    );
  }

  return urlWithRegionAndRadius;
};

/**
 * Obtain getContext request params from filters
 * @param params Filter params
 * @return getContext request params
 */
export const getContextRequestParamsFromFilters = (params = {}) => {
  const { filters, sorting, selectedRegions, radius, filterSet } = params;

  const requestParams = {};

  const selectedRegionsIds = selectedRegions;

  if (selectedRegionsIds.length) {
    requestParams.geo = { ids: selectedRegionsIds };

    if (radius) {
      requestParams.geo.radius = radius;
    }
  }

  const selectedFilters = filters.reduce(
    (res, filter) => {
      if (filter.selected) {
        switch (filter.id) {
          case "1101":
            res.brand = filter.values.find(value => value.checked).slug;
            break;

          case "1102":
            res.model = filter.values.find(value => value.checked).slug;
            break;

          default:
            res.others.push(`${filter.id}=${filterToExpressionValue(filter)}`);
        }
      }

      return res;
    },
    { brand: null, model: null, others: [] },
  );

  if (selectedFilters.brand != null) {
    requestParams.resourcePath =
      selectedFilters.brand +
      (selectedFilters.model ? `/${selectedFilters.model}` : "");
  }

  requestParams.expressions = [];
  if (selectedFilters.others.length > 0) {
    requestParams.expressions = selectedFilters.others;
  }

  if (sorting != null) {
    const [sortBy, sortDir] = sorting.split("_");

    requestParams.sortBy = sortBy;
    requestParams.sortDir = sortDir;
  }

  requestParams.filterSet = filterSet || "ALL";

  return requestParams;
};

export const getCarsList = (dealer, showAll) => {
  if (!showAll) {
    let carWithMinimumPrice = dealer.variants[0];
    dealer.variants.forEach(car => {
      if (car.price.low < carWithMinimumPrice.price.low) {
        carWithMinimumPrice = car;
      }
    });
    return [dealer.variants[0]];
  }
  return dealer.variants;
};
